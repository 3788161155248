import React from 'react';
import Header from '../components/glopal/Header';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const PrivacyPolicy = () => {
        
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Header page_name={"سياسة الخصوصية"} />
            <Container>
                <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: { md: 4 ,xs:1} }}>
                    <Typography variant={isMobile ?'h5':'h4'} color={'#BF9363'} mt={2}>
                        <bdi>سياسة الخصوصية لموقع "I'DOOR"</bdi>
                    </Typography>

                    {/* مقدمة */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant={isMobile ?'h5':'h4'} color={'#000'}>
                            <bdi>مقدمة</bdi>
                        </Typography>
                        <Typography color={'#969393'} fontSize={isMobile?'small':'unset'}>
                            <bdi>نحن في "I'DOOR" نولي أهمية كبيرة لخصوصية مستخدمينا. تهدف هذه السياسة إلى توضيح كيفية جمع واستخدام وحماية المعلومات الشخصية التي يتم تقديمها عبر موقعنا.</bdi>
                        </Typography>
                    </Box>

                    {/* جمع المعلومات */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant={isMobile ?'h5':'h4'} color={'#000'}>
                            <bdi>جمع المعلومات</bdi>
                        </Typography>
                        <Typography color={'#969393'} fontSize={isMobile?'small':'unset'}>
                            <bdi>قد نقوم بجمع المعلومات التالية عند استخدامك لموقعنا: معلومات شخصية مثل الاسم، البريد الإلكتروني، رقم الهاتف، والعنوان، وذلك عند التسجيل أو تقديم طلبات. معلومات الاستخدام: مثل عنوان IP، نوع المتصفح، الصفحات التي تم زيارتها، ومدة الزيارة.</bdi>
                        </Typography>
                    </Box>

                    {/* استخدام المعلومات */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant={isMobile ?'h5':'h4'} color={'#000'}>
                            <bdi>نستخدم المعلومات التي نجمعها للأغراض التالية</bdi>
                        </Typography>
                        <Typography color={'#969393'} fontSize={isMobile?'small':'unset'}>
                            <bdi>• تحسين خدماتنا: لفهم احتياجاتك وتقديم خدمات مخصصة.</bdi><br />
                            <bdi>• التواصل: للرد على استفساراتك وتزويدك بالمعلومات المطلوبة.</bdi><br />
                            <bdi>• التسويق: لإرسال عروض ترويجية أو معلومات حول منتجاتنا، مع إمكانية إلغاء الاشتراك في أي وقت.</bdi><br />
                            <bdi>• ملفات تعريف الارتباط (Cookies): يستخدم موقعنا ملفات تعريف الارتباط لتحسين تجربة المستخدم، يمكنك تعديل إعدادات المتصفح لرفض ملفات تعريف الارتباط، ولكن قد يؤثر ذلك على بعض وظائف الموقع.</bdi>
                        </Typography>
                    </Box>

                    {/* مشاركة المعلومات */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant={isMobile ?'h5':'h4'} color={'#000'}>
                            <bdi>مشاركة المعلومات مع أطراف ثالثة</bdi>
                        </Typography>
                        <Typography color={'#969393'} fontSize={isMobile?'small':'unset'}>
                            <bdi>نحن لا نبيع أو نؤجر أو نشارك معلوماتك الشخصية مع أطراف ثالثة إلا في الحالات التالية:</bdi><br />
                            <bdi>• مقدمو الخدمات: قد نشارك المعلومات مع شركات تقدم خدمات بالنيابة عنا، مثل معالجة المدفوعات أو تحليل البيانات، مع التزامهم بحماية المعلومات.</bdi><br />
                            <bdi>• الالتزام القانوني: إذا طُلب منا ذلك بموجب القانون أو لحماية حقوقنا القانونية.</bdi>
                        </Typography>
                    </Box>

                    {/* حماية المعلومات */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant={isMobile ?'h5':'h4'} color={'#000'}>
                            <bdi>حماية المعلومات</bdi>
                        </Typography>
                        <Typography color={'#969393'} fontSize={isMobile?'small':'unset'}>
                            <bdi>نستخدم تدابير أمنية مناسبة لحماية معلوماتك من الوصول غير المصرح به أو التعديل أو الكشف أو التدمير.</bdi>
                        </Typography>
                    </Box>

                    {/* حقوق المستخدم */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant={isMobile ?'h5':'h4'} color={'#000'}>
                            <bdi>حقوق المستخدم</bdi>
                        </Typography>
                        <Typography color={'#969393'} fontSize={isMobile?'small':'unset'}>
                            <bdi>يحق لك الوصول إلى معلوماتك الشخصية وتحديثها أو طلب حذفها. للمساعدة، يرجى التواصل معنا عبر 0575151539.</bdi>
                        </Typography>
                    </Box>

                    {/* تعديلات على سياسة الخصوصية */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant={isMobile ?'h5':'h4'} color={'#000'}>
                            <bdi>تعديلات على سياسة الخصوصية</bdi>
                        </Typography>
                        <Typography color={'#969393'} fontSize={isMobile?'small':'unset'}>
                            <bdi>قد نقوم بتحديث هذه السياسة من وقت لآخر. سيتم نشر أي تغييرات في هذه الصفحة، ونشجعك على مراجعتها بانتظام.</bdi>
                        </Typography>
                    </Box>

                    {/* التواصل معنا */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant={isMobile ?'h5':'h4'} color={'#000'}>
                            <bdi>التواصل معنا</bdi>
                        </Typography>
                        <Typography color={'#969393'} fontSize={isMobile?'small':'unset'}>
                            <bdi>إذا كان لديك أي استفسار حول سياسة الخصوصية، يرجى التواصل معنا عبر 0575151539.</bdi>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default PrivacyPolicy;
