import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function NavDrawer({ setDrawer, drawer }) {
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor="left"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                >
                    <Box
                        sx={{
                            width: 300,
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            padding: "40px 20px",
                        }}
                    >

                        <Link
                            to="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Button style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>

                                {"الرئيسية"}
                            </Button>
                        </Link>
                        <Link
                            to="/catalog"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Button style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>

                                {"الكتالوج"}
                            </Button>
                        </Link>
                        <Link
                            to="/contact-us"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Button style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>

                                {"تواصل معنا"}
                            </Button>
                        </Link>

                        <Link
                            to="/about-us"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Button style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>

                                {"من نحن"}
                            </Button>
                        </Link>
                        <Link
                            to="/privacy-policy"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Button style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>

                                {"سياسة الخصوصية"}
                            </Button>
                        </Link>
                        <Link
                            to="/request-quote"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Button style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>

                                {"طلب عرض سعر"}
                            </Button>
                        </Link>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
