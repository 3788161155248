import { Box, CardMedia } from '@mui/material'
import React from 'react'
// import cardImg from './../../../assetss/Rectangle 1202.png'
const CatalogCard = ({ cardImg, state, name, offer, price, col }) => {
    return (
        <>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, position: 'relative', padding: '10px', borderRadius: '10px', backgroundColor: '#fff', zIndex: '99' }}>
                <CardMedia component={'img'} src={cardImg} sx={{ objectFit: 'fill', height: '500px', borderRadius: '25px' }} />
                {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} > */}
                {/* <Typography sx={{ p: '4px 8px', backgroundColor: { backgroundColor: col }, color: '#FFF', borderRadius: '10px', textAlign: 'center', width: '75px' }} >{state}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                        <Typography variant='h5' sx={{ fontWeight: 'bold' }} >{name}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: '600', fontSize: '1.4rem', }} style={{ textDecoration: 'lineThrough' }} >{offer}</Typography>
                            <Typography sx={{ fontWeight: '600', fontSize: '1.4rem', border: '1px solid #F7C42B', padding: '0 8px', borderRadius: '8px', color: '#F7C42B' }} >{price}</Typography>
                        </Box>
                    </Box> */}
                {/* </Box> */}
            </Box>
        </>

    )
}

export default CatalogCard