import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { CardMedia, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer.jsx";
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logo from './../../assetss/Logo/logoD.png'
import './Nav.css'
function NavBar() {
    const [drawer, setDrawer] = useState(false);
    return (
        <>
            <AppBar position="static" elevation={0}
                style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor: "#253441",
                }} >
                <Container>
                    <Toolbar disableGutters sx={{ gap: { md: '0', xs: 2 } }} >

                        <Link
                            to="/"
                            style={{
                                color: "#FFF",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                                width: '100px',
                                objectFit: 'fill'
                            }}
                        >
                            <CardMedia component={'img'} src={logo} />
                        </Link>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: 8,
                                marginInlineStart: 8
                            }}
                        >
                            <Link
                                to="/"
                                style={{
                                    color: "#FFF",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                                className='hovLink'
                            >
                                {"الرئيسية"}
                            </Link>
                            <Link
                                to="/catalog"
                                style={{
                                    color: "#FFF",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                                className='hovLink'
                            >
                                {"الكتالوج"}
                            </Link>
                            <Link
                                to="/contact-us"
                                style={{
                                    color: "#FFF",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                                className='hovLink'
                            >
                                {"تواصل معنا"}
                            </Link>
                            <Link
                                to="/about-us"
                                style={{
                                    color: "#FFF",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                                className='hovLink'
                            >
                                {"من نحن"}
                            </Link>
                            <Link
                                to="/privacy-policy"
                                style={{
                                    color: "#FFF",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                                className='hovLink'
                            >
                                {"سياسة الخصوصية"}
                            </Link>
                            <Link
                                to="/request-quote"
                                style={{
                                    color: "#FFF",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                                className='hovLink'
                            >
                                {"طلب عرض سعر"}
                            </Link>
                        </Box>
                        {/* <Link
                            to="https://whas.me/o0uop6jdaX"
                            target="_blank"
                            style={{
                                color: "#FFF",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}
                        >
                            <Button variant="contained" startIcon={<WhatsAppIcon />} sx={{ backgroundColor: '#45C655', color: '#FFF', gap: 2, fontSize: '1rem' }} >
                                Whats App
                            </Button>
                        </Link> */}
                        <Box
                            sx={{
                                flexGrow: 1,

                                display: { md: "none", xs: "flex" },
                                justifyContent: "end",
                            }}>
                            <IconButton onClick={() => setDrawer(true)}>
                                <MenuIcon sx={{ color: '#FFF', fontSize: '2rem' }} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
