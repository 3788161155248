import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
const ContactData = () => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#253441', p: 3, color: '#FFF', height: '50vh', alignItems: 'flex-start', justifyContent: 'space-around', position: 'relative', borderRadius: '30px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexDirection: 'column' }} >
                    <Typography variant='h3' >مرحبًا بك في IDOOR!</Typography>
                    <Typography variant='h5' > طلب عرض سعر للأبواب الداخلية</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={2} >
                    <Box >
                        <Typography variant='h6'>نحن نقدم مجموعة متنوعة من الأبواب الداخلية التي تتميز بالجودة العالية، التصاميم العصرية، والأسعار التنافسية. إذا كنت تبحث عن الباب المثالي لمنزلك أو مشروعك، فنحن هنا لخدمتك.</Typography>
                    </Box>
                    <Box >
                        <Typography variant='h6'>يرجى ملء النموذج التالي للحصول على عرض السعر الخاص بك</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                    <Avatar sx={{ height: '100px', width: '100px', background: '#8d959a', color: '#8d959a' }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-around', position: 'absolute', top: '85%', left: '10%' }}>
                        <WhatsAppIcon sx={{ color: '#FFF', fontSize: '2.5rem' }} />
                        <InstagramIcon sx={{ color: '#FFF', fontSize: '2.5rem' }} />
                        <FacebookIcon sx={{ color: '#FFF', fontSize: '2.5rem' }} />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ContactData