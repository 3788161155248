import React from 'react'
import Header from '../components/glopal/Header'
import { Box, Container, Grid } from '@mui/material'
import ContactData from '../components/ContactRequestQuote/ContactData/ContactData'
import FormLogic from '../components/ContactRequestQuote/Form/FormLogic'

const RequestQuote = () => {
    return (
        <>
            <Header page_name={"استمارة طلب عرض سعر"} />
            <Box p={3} >
                <Container>
                    <Grid container spacing={4} sx={{ display: 'flex', alignItems: 'center' }} >
                        <Grid item md={4} xs={12}>
                            <ContactData />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <FormLogic />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default RequestQuote