import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import CatalogCard from './CatalogCard/CatalogCard'
import D1 from './../../assetss/door/005.jpg'
import D2 from './../../assetss/door/002.jpg'
import D3 from './../../assetss/door/009.jpg'
import D4 from './../../assetss/door/MPR_08.jpg'
import D5 from './../../assetss/door/05274ace736c6c1cfcdd4fe83aedbaf9.jpg'
import D6 from './../../assetss/door/10_-_Blanco_Antico__SH213-2.jpg.400x0_q85.jpg'
import D7 from './../../assetss/door/10e6b1931c89529d17b6106055864d57.jpg'
import D8 from './../../assetss/door/11_-_Wenge_horizontal_19502.jpg.400x0_q85.jpg'
import D9 from './../../assetss/door/12_-_Bi_Drift_Wood_0771.jpg.400x0_q85 (1).jpg'
import D10 from './../../assetss/door/12_-_Bi_Drift_Wood_0771.jpg.400x0_q85.jpg'
import D11 from './../../assetss/door/13_-_Rose_Wood_9601_TM45_.jpg.400x0_q85.jpg'
import D12 from './../../assetss/door/14_-_Dark_Grey_Oak_64T.jpg.400x0_q85.jpg'
import D13 from './../../assetss/door/15_-_Off_White_40029_TM_101_.jpg.400x0_q85.jpg'
import D14 from './../../assetss/door/MPR_14.jpg'
import D15 from './../../assetss/door/16_-_Walnut_Horizontal_10202.jpg.400x0_q85.jpg'
import D16 from './../../assetss/door/17_-_Zebrano_2503.jpg.400x0_q85.jpg'
import D17 from './../../assetss/door/18_-_Blanco_S0102.jpg.400x0_q85.jpg'
import D18 from './../../assetss/door/19_-_Drift_Wood_3371.jpg.400x0_q85.jpg'
import D19 from './../../assetss/door/1_-_Wenge_8701_Dlvm2Pt.jpg.400x0_q85.jpg'
import D20 from './../../assetss/door/GD_03.jpg.300x0_q85.jpg'
import D21 from './../../assetss/door/1d05379b7163a231293ca7339d66b027.jpg'
import D31 from './../../assetss/door/GD_04.jpg.300x0_q85.jpg'
import D32 from './../../assetss/door/GD_04_mffDV4t.jpg'
import D33 from './../../assetss/door/GD_05.jpg.300x0_q85.jpg'
import D34 from './../../assetss/door/GD_05_ecM7Mpp.jpg'
import D35 from './../../assetss/door/GD_06.jpg.300x0_q85.jpg'
import D36 from './../../assetss/door/GD_09.jpg.300x0_q85.jpg'
import D22 from './../../assetss/door/TR_17.jpg'
import D23 from './../../assetss/door/TR_09.jpg'
import D24 from './../../assetss/door/TR_09_nboV5GL.jpg'
import D25 from './../../assetss/door/TR_13.jpg'
import D26 from './../../assetss/door/TR_13_uZztc2x.jpg'
import D27 from './../../assetss/door/TR_14.jpg'
import D28 from './../../assetss/door/TR_14_H7kW2bE.jpg'
import D29 from './../../assetss/door/TR_16.jpg'
import D30 from './../../assetss/door/TR_16_5r5W4n4.jpg'
import { Link } from 'react-router-dom'
const CatalogList = () => {
    return (
        <>
            <Box p={3} >
                <Container>
                    <Grid container spacing={4} >
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D1} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D2} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'غير متاح'} col={'#C64545'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D3} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D4} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D5} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'غير متاح'} col={'#C64545'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D6} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D7} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D8} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D9} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D10} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D12} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D11} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D13} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D14} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D15} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D16} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D17} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D18} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D19} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D20} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D21} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D22} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D23} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D24} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D25} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D26} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D27} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D28} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D29} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D30} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D31} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D32} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D33} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D34} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D35} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                        <Grid item position={'relative'} md={4} xs={12}>
                            <Box>
                                <Typography sx={{ width: '120%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                                <CatalogCard cardImg={D36} name={'نص إفتراضي بإسم المنتج'} offer={'$200'} price={'$100'} state={'متاح'} col={'#45C655'} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ textAlign: 'center', mt: 4 }} >
                        <Link to={'https://drive.google.com/file/d/125C4ntvOH3UIpXNrHj1PVflqUR31tMi7/view?usp=sharing'} target='_blank' >
                            <Button sx={{ background: '#F7C42B', color: '#000', fontSize: '1.5rem', "&:hover": { backgroundColor: '#F7C42B' } }} >تحميل الكتالوج</Button>
                        </Link>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default CatalogList