import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import header from './../../assetss/Header/flower.png'
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';

const Header = ({ page_name }) => {
    
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    const location = useLocation()
    let page = location.pathname.split('/')[1]
    return (
        <Box position={'relative'} sx={{backgroundImage: `url(${header})`, height: '35rem', backgroundSize: 'cover', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <div className="overlay"></div>
            <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative', zIndex: 3, alignItems: 'center' }} >
                <Typography variant={isMobile ? 'h6' : 'h4'} color={'#FFF'} >{`${'الرئيسية'} /  ${page_name || page}`}</Typography>
            </Box>
        </Box>
    )
}

export default Header