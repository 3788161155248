import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useState } from 'react'
import './FormLogic.css'
import { useForm } from 'react-hook-form';
import { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { enqueueSnackbar } from "notistack";
const FormLogic = () => {
    const [type, setType] = useState('شركة');

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };
    const form = useRef()
    const { register, handleSubmit, reset } = useForm();
    const sendEmail = (e) => {
        // e.preventDefault();
        emailjs.sendForm('service_ex904dg', 'template_ugwv4ys', form.current, 'jAiTOuzQ-ypm7BsGL')
            .then((result) => {
                console.log(result.text);
                enqueueSnackbar("Data sent successfully👍")
                reset()
            }, (error) => {
                console.log(error.text);
                enqueueSnackbar(error, { variant: 'error' });
                reset()
            });
        reset()
    }
    // const onSubmit = data => console.log(data);
    return (
        <>
            <Box>
                <form ref={form} onSubmit={handleSubmit(sendEmail)} mt={1} name="from_name">
                    <Box>
                        <Grid container rowGap={10} >
                            <Grid item md={6} xs={12}>
                                <TextField
                                    // defaultValue={'الاسم الاول'}
                                    name="full_name"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    {...register("full_name", { required: true })}
                                    id="standard-basic" label="الاسم بالكامل" variant="standard"
                                    sx={{
                                        fontSize: '1.5rem', minWidth: '90%', '.MuiFormControlLabel-label': {
                                            fontSize: '1.5rem',
                                            fontWeight: 'bold'
                                        },
                                        '&.MuiFormControlLabel-root': { m: 0, mx: '4px', fontSize: '1.5rem', },
                                        '.MuiFormLabel-root': { m: 0, mx: '4px', fontSize: '1.5rem', },
                                    }} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    name="phone"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    {...register("phone", { required: true })}
                                    // defaultValue={'رقم الهاتف'}
                                    id="standard-basic" label="رقم الهاتف" variant="standard"
                                    sx={{
                                        fontSize: '1.5rem', minWidth: '90%', '.MuiFormControlLabel-label': {
                                            fontSize: '1.5rem',
                                            fontWeight: 'bold'
                                        },
                                        '&.MuiFormControlLabel-root': { m: 0, mx: '4px', fontSize: '1.5rem', },
                                        '.MuiFormLabel-root': { m: 0, mx: '4px', fontSize: '1.5rem', },
                                    }} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    name="email"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    {...register("email", { required: true })}
                                    // defaultValue={'البريد الالكتروني'}
                                    type='email'
                                    id="standard-basic" label="البريد الالكتروني" variant="standard"
                                    sx={{
                                        fontSize: '1.5rem', minWidth: '90%', '.MuiFormControlLabel-label': {
                                            fontSize: '1.5rem',
                                            fontWeight: 'bold'
                                        },
                                        '&.MuiFormControlLabel-root': { m: 0, mx: '4px', fontSize: '1.5rem', },
                                        '.MuiFormLabel-root': { m: 0, mx: '4px', fontSize: '1.5rem', },
                                    }} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    name="number_door"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    {...register("number_door", { required: true })}
                                    // defaultValue={'الاسم الأخير'}
                                    id="standard-basic" label="عدد الأبواب المطلوبة"
                                    variant="standard"
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, ''); // يمنع الأحرف غير الرقمية
                                    }}
                                    inputProps={{
                                        min: 1 // يسمح فقط بالقيم الموجبة
                                    }}
                                    sx={{
                                        fontSize: '1.5rem', minWidth: '90%', '.MuiFormControlLabel-label': {
                                            fontSize: '1.5rem',
                                            fontWeight: 'bold'
                                        },
                                        '&.MuiFormControlLabel-root': { m: 0, mx: '4px', fontSize: '1.5rem', },
                                        '.MuiFormLabel-root': { m: 0, mx: '4px', fontSize: '1.5rem', },
                                    }} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{ width: '100%', textAlign: 'start' }}>
                                    {/* <InputLabel htmlFor={'type'} sx={{ my: 1, fontWeight: 'bold', color: theme => theme.palette.primary.default, fontSize: '1.5rem', }}>
                                        {"نوع العميل"}
                                    </InputLabel> */}
                                    <RadioGroup row value={type} onChange={handleTypeChange} sx={{ mb: 2, }} name='type_client'>
                                        <FormControlLabel value="فرد" control={<Radio size="medium" color="primary" />}
                                            sx={{
                                                '&.MuiFormControlLabel-root': { m: 0, mx: '4px', fontSize: '1.5rem', },
                                                '.MuiFormControlLabel-label': {
                                                    fontSize: '1.5rem',
                                                    fontWeight: 'bold'
                                                },
                                            }}
                                            label={'فرد'} />

                                        <FormControlLabel value="شركة" control={<Radio size="medium" color="primary" />}
                                            sx={{
                                                '.MuiFormControlLabel-root': { m: 0, mx: '4px', fontSize: '1.5rem', },
                                                '.MuiFormControlLabel-label': {
                                                    fontSize: '1.5rem',
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                            label={'شركة'} />
                                    </RadioGroup>
                                </Box>
                            </Grid>
                            {/* <Grid item md={12} xs={12}>
                                <TextField
                                    name="message"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    {...register("message", { required: true })}
                                    // defaultValue={'رسالتك'}
                                    id="filled-multiline-flexible"
                                    label="رسالتك"
                                    multiline
                                    maxRows={4}
                                    variant="filled"
                                    sx={{ fontSize: '1.5rem' }}
                                    fullWidth
                                />
                            </Grid> */}
                        </Grid>
                        <Box sx={{ textAlign: 'center', mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ color: '#FFF', p: '13px 75px', textAlign: 'center', backgroundColor: '#253441', borderRadius: '8px', fontSize: '1.3rem' }} >إرسال</Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </>
    )
}

export default FormLogic