import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import about from './../assetss/AboutUs/Rectangle 32.png'
const AboutUs = () => {
    return (
        <>
            <Box sx={{ p: { md: '100px', xs: '0' }, backgroundColor: '#FEF9EA', position: 'relative', mt: { md: 0, xs: '40px' },overflow:'hidden' }} >
                <Typography className="test" sx={{
                    border: '2px solid #F7C42B',
                    position: 'absolute',
                    width: '600px',
                    height: '600px',
                    top: { md: '10%', xs: '-20%' },
                    left: '85%',
                    overflow: 'hidden',
                    rotate: '45deg'
                }}></Typography>
                <Container>
                    <Box>
                        <Grid container spacing={{ md: 5, xs: 1 }} sx={{
                            alignItems: 'center',
                            width: { md: '100%', xs: '    width: calc(100% + 20px);' },
                            p: { md: '50px', xs: '0' },
                            backgroundColor: '#FFF',
                            borderBottomLeftRadius: '150px',
                            borderTopLeftRadius: '150px'
                        }} >
                            <Grid item md={6} xs={12}>

                                <CardMedia component={'img'} src={about} sx={{ position: 'relative' }} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box>
                                    <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#F7C42B' }}>شركة I’DOOR</Typography>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>نحن شركة سعودية متخصصة في صناعة الديكورات الداخلية والأبواب الداخلية، نقدم أجود الخامات والتصاميم العالمية تناسب جميع الأذواق باستخدام أحدث التقنيات.
                                        أبواب صنعت خصيصا لأجلك علي طراز فريد، ضد الماء ومكونة من أخشاب طبيعية، بنضملك جودة،وسعر منافس،دقة في الصنع،وأعلي مستويات الأمان، تدوم معك طويلا ولا تحتاج إلي صيانة دورية وتتميز بثبات الألوان.
                                        نعمل بدقة متناهية وجهد كبير حتي نصل بوحدتك السكنية إلي المستوي المطلوب لدينا خبرة 15 عام في المجال، لدينا قدرة إنتاجية عالية ونصل إلي كافة أنحاء المملكة.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                
            </Box>
        </>
    )
}

export default AboutUs